import React from 'react'
import './Home.css';
import { Navbar,Nav,Form,FormControl,Button,NavDropdown,Col,Card}from 'react-bootstrap';
import { Link } from 'react-router-dom'
import Navbars from './Navbar.js'
import Carousels from './Carousel.js'
import Image from 'react-bootstrap/Image'
import Carousel from 'react-bootstrap/Carousel'
import { ListGroup, ListGroupItem ,CardDeck,Row,Container} from 'react-bootstrap';
import Footers3 from './Footer3';
import TentangKamis from './TentangKami.js';


const AboutUs=()=>{
    return(
        <div>
          <meta name="theme-color" content="#ff6600" />
            <div className="pageContainer">
                <div className="headerContainer">    
        <Navbars></Navbars> 
        </div>
        <div className="bodyContainer">
        </div>

        <div className="containerTitle">

<h2 className="text-center">Tentang Kami</h2> 
</div>

      <TentangKamis></TentangKamis>

<div className="visi">
<h2>Visi</h2> 
<div className="spaceKosongVisi"></div>
    <h5>Menjadi Pemain Forming Terbaik dari Sabang sampai Merauke.</h5>
</div>

<div className="misi">
<h2>Misi</h2> 
<div className="spaceKosongMisi"></div>

<h5>
<ol>
  <li>Membangun TEAM melalui Sistim Pelatihan, Reward & Punishment<br></br>Transparansi dan Manajemen Sistim kerja terukur.<br></br></li>
  <li>Memperluas cakupan pasar dengan membuka cabang </li>
  <li>Meningkatkan sistem manajemen dengan mencapai ISO 1991</li>
</ol> 
</h5>
</div>



<Footers3></Footers3> 
        </div>
        </div>
        
    )
} 
export default AboutUs;