import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import './Carousel.css'


const carousels =()=>
<Carousel className="carousel">
<Carousel.Item className="carouselImage"> 
  <img
    className="d-block w-100"
    src="carousel01.jpeg"
    alt="First slide"
  />
  <Carousel.Caption>
    {/* <h4>PT. Harapan Fajar Gemilang Produsen Bahan Bangunan Berbahan Baja</h4> */}
    {/* <h5>Perusahaan yang sudah terkenal di Indonesia yang bergerak dibidang produsen bahan bangunan baja .</h5> */}
  </Carousel.Caption>
</Carousel.Item>
<Carousel.Item className="carouselImage">
  <img 
    className="d-block w-100"
    src="carousel02.jpeg"
    alt="Third slide"
  />

  <Carousel.Caption>
   
  </Carousel.Caption>
</Carousel.Item>
<Carousel.Item className="carouselImage">
  <img
    className="d-block w-100"
    src="carousel03.jpeg"
    alt="Third slide"
  />

  <Carousel.Caption>
  
  </Carousel.Caption>
</Carousel.Item>
</Carousel>

export default carousels;