import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import * as serviceWorker from './serviceWorker';

render(
    <BrowserRouter>
      <App/>
   
    </BrowserRouter>,
    document.querySelector('#root')
  )