import React,{useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Nav,Form,FormControl,Button,NavDropdown,Card,CardDeck,ListGroup} from 'react-bootstrap';
import './ContactUs.css';
import ReactDOM from 'react-dom'
import Navbars from './Navbar.js'
import Carousel from 'react-bootstrap/Carousel'
import { CardHeader } from 'react-bootstrap/Card';
import Footers from './Footer';
import Footers3 from './Footer3';



const ContactUs=()=>{

return(
<div>
<Navbars></Navbars>
<div className="containerTitleContact">

<h2 className="text-center">Hubungi Kami</h2> 

<div className="spaceKosongPreviewKontak"></div>

</div>
<div className="firstContactList"></div>

<div className="contactGreetings">
    <h5 style={{marginLeft:'10%',marginRight:'10%', textAlign:'center', padding:'auto'}}>Jika Anda memiliki pertanyaan atau membutuhkan informasi lebih lanjut tentang produk kami, Anda dapat menghubungi kontak kami atau mengirim pertanyaan Anda kepada kami melalui email atau telepon. Kami akan segera memberikan bantuan dan berusaha untuk merespon.</h5>
</div>

<CardDeck style={{marginBottom:'20px',justifyContent:'center'}} >
   
  <Card className="text-center" style={{marginLeft:'5%',marginRight:'5%' }} >
    <Card.Img variant="top" src="call.png" style={{  width: '9rem',marginLeft:'auto',marginTop:'5%',marginRight:'auto' }} className="justify-content-md-center" />
    <Card.Body>
      <Card.Title className="text-center p-3" > <h2>Office Number</h2></Card.Title>
      <ListGroup variant="flush">
    <ListGroup.Item style={{marginTop:'-5%' }}><h4>021-54394804</h4></ListGroup.Item>
  </ListGroup>
      <Card.Title className="text-center p-3"></Card.Title>
    
    </Card.Body>

  </Card>
  <Card className="text-center" style={{marginLeft:'5%',marginRight:'5%' }}>
    <Card.Img variant="top" src="email.png" style={{  width: '9rem',marginLeft:'auto',marginTop:'5%',marginRight:'auto'  }} className="justify-content-md-center" />
    <Card.Body>
      <Card.Title className="text-center p-3"> <h2>Email</h2></Card.Title>
      <ListGroup variant="flush">
    <ListGroup.Item style={{marginTop:'-5%' }}><h4>cs01.hfg@gmail.com</h4></ListGroup.Item>
  </ListGroup>
      <Card.Title className="text-center p-3"></Card.Title>
    
    </Card.Body>

  </Card>
  {/* <Card className="text-center"  >
    <Card.Img variant="top" src="call.png" style={{ width: '9rem',marginLeft:'auto',marginTop:'10%',marginRight:'auto' }} className="justify-content-md-center" />
    <Card.Body>
      <Card.Title className="text-center p-3"> <h2>Sales Number</h2></Card.Title>
      <Card.Title className="text-center p-3" style={{marginTop:'-20px'}}> <h2>Iwan</h2></Card.Title>

      <ListGroup variant="flush">
    <ListGroup.Item style={{marginTop:'-30px' }}><h4>0857-8082-2433</h4></ListGroup.Item>
    <ListGroup.Item style={{marginTop:'' }}><h4>0878-7568-0933</h4></ListGroup.Item>

  </ListGroup>
  <Card.Title className="text-center p-3" style={{marginTop:'-10px'}}> <h2>Wira</h2></Card.Title>

<ListGroup variant="flush">
<ListGroup.Item style={{marginTop:'-30px' }}><h4>0878-8320-3699</h4></ListGroup.Item>
<ListGroup.Item style={{marginTop:'' }}><h4>0857-8082-2477</h4></ListGroup.Item>

</ListGroup> */}
    
    {/* </Card.Body>
    
   

  </Card> */}

  
  
</CardDeck>


<Footers3></Footers3> 
</div>
)}

export default ContactUs;

