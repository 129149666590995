import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar,Nav,Form,FormControl,Button,NavDropdown, NavbarBrand} from 'react-bootstrap';
import './Navbar.css'

const NavBars=()=>{
return(

<div>
<div className='navigationBar'>
        <Navbar id={'companyLogo'} className="color-nav" collapseOnSelect expand="xl" bg="primary" variant="dark">
          <Navbar.Brand href="">
       <img
       style={{marginBottom:'-10px'}}
        src="logohfg.png"
        width="150"
        height="60"
        className='brand2'
         />
         <p></p>
         <h5 className='brand1' style={{fontSize:"12px",textAlign:"center"}}>Here for Better Generation</h5>        
      </Navbar.Brand>
      {/* <NavbarBrand style={{marginTop:'auto',marginLeft:'0%'}}> <h5 style={{}}>Produsen Bahan Bangunan Berbahan Baja</h5></NavbarBrand> */}

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" style={{marginTop:'auto'}}>
            <Nav>
              <Nav.Link href="/" className="navbarItems" >
                <h5 id={'h5NavbarItems'}>
                <img 
                src="home.png"></img>
                  Home</h5>
                
                </Nav.Link>
                <Nav.Link href="/AboutUs" className="navbarItems" >
                <h5 id={'h5NavbarItems'}>
                <img src="business.png"></img>
                  About Us</h5>
                
                </Nav.Link>
              <Nav.Link href="/productList" className="navbarItems">
                <h5>
              <img src="product.png"></img>
                Product List
                </h5>
                </Nav.Link>
              <Nav.Link eventKey={2} href="/ContactUs" className="navbarItems">
                <h5>
                <img src="contact.png"></img>
                  Contact Us
                
                  </h5>
                </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        </div>
        
</div>
    

)

}

export default NavBars;