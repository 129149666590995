import React from 'react'
import Navbars from './Navbar.js'
import CardSliders from './CardSlider'
import './Product.css';

import { ListGroup, ListGroupItem ,CardDeck,Row,Container} from 'react-bootstrap';
import { Navbar,Nav,Form,FormControl,Button,NavDropdown,Col,Card}from 'react-bootstrap';
import Footers from './Footer.js';
import Footers3 from './Footer3';





const productList=()=>{
    return(
        <div>
            <div className="pageContainer">
            <div className="headerContainer">
        <Navbars></Navbars>
        </div>
<div className="containerTitle">

<h2 className="text-center">Produk Kami</h2> 
</div>
<h5 className="productGreeting" style={{textAlign:'center',marginLeft:'10%',marginRight:'10%'}}>PT.Harapan Fajar Gemilang memiliki 6 product yaitu Spandeck, Bondeck, Hollow, Seng Galvanis, Seng Warna dan Paku. yang kami buat dengan kualitas tinggi , presisi dan harga yang sangat affordable sehingga menjadikan product kami menjadi product yang worth to buy.</h5>
<div className="firstProductList">
<CardDeck>
  <Card>
    <Card.Img variant="top" src="trimdeck.png" />
    <Card.Body>
      <Card.Title className="text-center">Spandeck</Card.Title>
      <Card.Text>
      <h5>Kelebihan:</h5>
        <ul>
          <li>
          Bahan dasar galvalum ,memberikan masa pakai yang lebih lama</li>
          <li>Banyak di applikasikan untuk atap atau pagar</li>
        </ul>
        <br/> <h5>Warna:</h5> Merah Merapi, Biru mudah, Biru Damai dan Merah Maroon
      </Card.Text>
      <Card.Img variant="top" src="spektrimdeck.png" />
    </Card.Body>
    <Card.Footer>
      <small className="text-muted">&copy; Harapan Fajar Gemilang 2020</small>
    </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src="Bondeck.png"  />
    <Card.Body>
      <Card.Title className="text-center">Bondeck</Card.Title>
      <Card.Text>
      <h5>Kelebihan:</h5>
      <ul>
      <li>Peningkatan pesat pada daya rekat semen</li>
      <li>Peningkatan pesat pada kekuatan struktural bondeck</li>
      <li>Peningkatan pesat pada ketahanan terhadap getaran</li>
      <li>Menampilkan gaya akustik dan berfungsi meredam suara</li>
      <li>Berbahan dasar Galvanis atau Galvalum</li>
      Banyak di applikasikan untuk pengecoran bangunan pengganti pengecoran konventional     
      </ul>
      </Card.Text>
      <Card.Img variant="top" src="spekbondeck.png" />
    </Card.Body>
    <Card.Footer>
      <small className="text-muted">&copy; Harapan Fajar Gemilang 2020</small>
    </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src="hollow.png" />
    <Card.Body>
      <Card.Title className="text-center">Hollow</Card.Title>
      <Card.Text>
      <h5>Kelebihan:</h5>
        <ul>
<li>Bahan dasar galvalum, berat lebih ringan sehingga pelaksanaan pekerjaan pemasangan lebih cepat</li>
<li>Banyak di applikasikan untuk plafond gypsum</li>
<li>Tahan karat dan tidak menjalarkan api</li>
<li>Umur pemeliharaan lebih panjang</li>
        </ul>
      <br/><br/><br/><br/>
      </Card.Text>
      <Card.Img variant="top" src="spekhollow.png" />

    </Card.Body>
    <Card.Footer>
      <small className="text-muted">&copy; Harapan Fajar Gemilang 2020</small>
    </Card.Footer>
  </Card>
</CardDeck>

<CardDeck style={{marginTop:"50px"}}>
  <Card>
    <Card.Img variant="top" src="senggalvanis.png" />
    <Card.Body>
      <Card.Title className="text-center">Seng Galvanis</Card.Title>
      <Card.Text >
      <h5>Model Ukuran:</h5>
      <ul style={{columns:'2'}}> 
    
      <ul> 
Model Gelombang GG
<li>0.2 x 2.5 x 5</li>
<li>0.2 x 2.5 x 6</li>
<li>0.2 x 2.5 x 7</li>
<li>0.2 x 2.5 x 8</li>
<li>0.2 x 2.5 x 9</li>
<li>0.2 x 2.5 x 10</li>
</ul>

<ul> 
Model Gelombang AA
<li>0.2 x 3 x 5</li>
<li>0.2 x 3 x 6</li>
<li>0.2 x 3 x 7</li>
<li>0.2 x 3 x 8</li>
<li>0.2 x 3 x 9</li>
<li>0.2 x 3 x 10</li>
</ul>

</ul>

<ul style={{columns:'2'}}> 
      <ul> 
Model Plat GG
<li>0.2 x 2.5 x 5</li>
<li>0.2 x 2.5 x 6</li>
<li>0.2 x 2.5 x 7</li>
<li>0.2 x 2.5 x 8</li>
<li>0.2 x 2.5 x 9</li>
<li>0.2 x 2.5 x 10</li>
</ul>

<ul> 
Model Plat AA
<li>0.2 x 3 x 5</li>
<li>0.2 x 3 x 6</li>
<li>0.2 x 3 x 7</li>
<li>0.2 x 3 x 8</li>
<li>0.2 x 3 x 9</li>
<li>0.2 x 3 x 10</li>
</ul>
</ul>
      </Card.Text>
    
    </Card.Body>
    <Card.Footer>
      <small className="text-muted">&copy; Harapan Fajar Gemilang 2020</small>
    </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src="zincalum.png "  />
    <Card.Body>
      <Card.Title className="text-center">Zinc Alum</Card.Title>
      <Card.Text>
        <h5>Merek: </h5>
        <p>Gajah Galum </p>
        <h5>Model Gelombang</h5>
      <ul>
          <li>0.20 x 1.8meter</li>
          <li> 0.25 x 1.8meter</li>
      </ul>

        

      <h5>Warna:</h5>
      Merah Maroon, Merah Merapi, Merah Sunrise, Biru Bromo, Hijau, Biru Resin.
      <br/><br/>
      
      <h5>Kelebihan:</h5>
      <ul>
          <li>Awet dan tahan lama</li>
          <li>Tidak mudah keropos</li>
          <li>Harga terjangkau</li>
          <li>Proses installasi mudah</li>
          <li>Ramah lingkungan(recyclable)</li>
          <li>Tahan panas</li>
          <li>Tidak mudah retak</li>
   
      </ul>
      
      </Card.Text>
    </Card.Body>
    <Card.Footer>
      <small className="text-muted">&copy; Harapan Fajar Gemilang 2020</small>
    </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src="paku.png" />
    <Card.Body>
      <Card.Title className="text-center">Paku Bintang Ampera</Card.Title>
      <Card.Text>
      <ul style={{justifyContent:"center",}}> 
<h5>Model Ukuran</h5>
<li>1/4 x 15</li>
<li>1 1/2 x 14</li>
<li>9 x 1</li>
<li>3 1/4 x 18</li>
<li>3 x 10</li>
<li>2 1/2 x 11</li>
<li>4 x 8</li>
<li>5 x 6</li>
</ul>
      </Card.Text>

    </Card.Body>
    <Card.Footer>
      <small className="text-muted">&copy; Harapan Fajar Gemilang 2020</small>
    </Card.Footer>
  </Card>
</CardDeck>
</div>
<Footers3></Footers3> 

</div>
</div>

        )
    }

export default productList;