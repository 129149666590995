import React from 'react'
import { Route, Switch ,BrowserRouter } from 'react-router-dom'
import Home from './Home'
import ContactUs from './ContactUs'
import productList from './Product'
import AboutUs from './AboutUs'


export default function App() {
  return (
    <Switch>
      <BrowserRouter>
      <Route exact  path="/" component={Home}/>
      <Route path="/ContactUs" component={ContactUs} />
      <Route path="/productList" component={productList} />
      <Route path="/AboutUs" component={AboutUs} />
      </BrowserRouter>  
    </Switch>
  )
}