import React from 'react'
import './TentangKami.css';



const TentangKamis=()=>{
    return(
        <div>
            <div className="tentangKamiContainer">
                <div className="tentangKamiImg">
                    <img className="imageCompany2" src="companythumb.png"></img>
                </div>
                <div className="tentangKamiText" style={{textAlign:'justify',marginLeft:'5%',marginRight:'5%'}}>
                  
                    <h5>
                    &emsp;&emsp;Group Harapan bergerak di forming dan lapis galvanis baja lembaran sejak tahun 1990. Pasar kami terdapat di seluruh Indonesia mulai dari Barat Indonesia (Sumatera, Kalimantan, Jawa Barat, Jawa Tengah) sampai dengan Indonesia Timur (Sulawesi, Jawa Timur, Maluku, Nusa Tenggara Barat & Timur dan Papua). Kapasitas Produksi Galvanis mencapai 80,000 ton per tahun dengan Merk Gajah Gading dan Angsa AA.
	                Tahun 2012, Kami memulai Bisnis Unit Baja Ringan yang seiring meningkatnya permintaan pasar kami mulai Badan Hukum Resmi atas nama PT. HARAPAN FAJAR GEMILANG. 

                    <br></br> &emsp;&emsp; Produk Andalan Kami sebagai berikut: Bondeck Akustik, Spandeck, Hollow Akustik, Baja Ringan, Seng Gelombang Galvanis, Seng Alum Gelombang Warna.


Kapasitas Forming mencapai 40,000 ton per tahun dengan Pasar dari Nusa sampai dengan Merauke.

Nilai yang kami pegang dan kami hormati antara lain: Kerja dengan Komitmen dan Target, Kerja dengan TEAM, Kerja dengan memperhatikan Kesejahteraan Karyawan, Kerja dengan mengutamakan Kepuasan Pelanggan.


</h5>
                </div>
            </div>
        </div>
    )}

    export default TentangKamis;