import React from 'react'
import './Footer3.css';
import { Row,Col,Container,ListGroup } from 'react-bootstrap';



const Footers3=()=>{
    return(
    <div className="footer3">
    <Container className="containerFooter3">
      <Row>
        <Col lg={5} className="companyImage">
          <img 
            src="logohfg.png"
            className="companyImage"
             />
             </Col>
        </Row>
        
        <Row>
        <Col lg={{span: 3}} style={{fontFamily:'Nunito'}} className="footerCopyright">&copy; Copyright 2020<br></br> PT. Harapan Fajar Gemilang</Col>
        </Row>

        <Row>
        <Col lg={{span:4}}  style={{fontFamily:'Nunito'}} className="footerAddress"> Address: <br></br>Jl. Daan Mogot Km. 11 No. 45 Kedaung Kali Angke Cengkareng Jakarta Barat 11710</Col>
        </Row>
      
    </Container>
    </div>
    )}
    export default Footers3;