import React from 'react'
import './Home.css';
import { Navbar,Nav,Form,FormControl,Button,NavDropdown,Col,Card}from 'react-bootstrap';
import { Link } from 'react-router-dom'
import Navbars from './Navbar.js'
import Carousels from './Carousel.js'
import Image from 'react-bootstrap/Image'
import Carousel from 'react-bootstrap/Carousel'
import { ListGroup, ListGroupItem ,CardDeck,Row,Container} from 'react-bootstrap';
import Footers3 from './Footer3';
import TentangKamis from './TentangKami.js';


const Home=()=>{
    return(
        <div>
          <meta name="theme-color" content="#ff6600" />
            <div className="pageContainer">
                <div className="headerContainer">    
        <Navbars></Navbars> 
        </div>
        <div className="bodyContainer">
        <Carousels></Carousels>
        </div>
{/* 
      <TentangKamis></TentangKamis> */}

{/* <div className="tentangKami">
<Container className="containerTentangKami">
  <Row >
    <Col  sm={-10} md={4} lg={5.5}>
      <img className="imageCompany" src="companythumb.png"></img>
      </Col>
    <Col  sm={{span:10.2, offset:0}} md={{ span: 10, offset: 2 }} lg={{ span: 8, offset: 0 }}>
      <h2>Tentang Kami</h2> 
      <h5>
  Group Harapan bergerak di forming dan lapis galvanis baja lembaran sejak tahun 1990. Pasar kami terdapat di seluruh Indonesia mulai dari Barat Indonesia (Sumatera, Kalimantan, Jawa Barat, Jawa Tengah) sampai dengan Indonesia Timur (Sulawesi, Jawa Timur, Maluku, Nusa Tenggara Barat & Timur dan Papua). Kapasitas Produksi Galvanis mencapai 80,000ton per tahun dengan Merk Gajah Gading dan Angsa AA.
	Tahun 2012, Kami memulai Bisnis Unit Baja Ringan yang seiring dengan pertumbuhan kami memulai Badan Hukum Resmi PT HARAPAN FAJAR GEMILANG dengan Produk Andalan Bondeck Akustik, Spandeck, Baja Ringan, Hollo Akustik. Kapasitas Forming Kami mencapai 40,000ton per tahun dengan Pasar Indonesia Barat dan Indonesia Tengah.
	Kami berkomitmen untuk terus tumbuh bersama TEAM dengan mengadopsi  3 Pondasi Inti Perusahaan, “Problem Solving Attitude, Work with Target & Commitment dan Appreciate Well Done Performance”. Salam Kerja dan Salam Kejar Sasaran.
    </h5>
    </Col>
  </Row>
</Container>
</div> */}
{/* 
<div className="visi">
<h2>Visi</h2> 
<div className="spaceKosongVisi"></div>
    <h5>Menjadi Pemain Forming Terbaik dari Sabang sampai Merauke</h5>
</div>

<div className="misi">
<h2>Misi</h2> 
<div className="spaceKosongMisi"></div>

<h5>(1) Membangun Manusia dengan pelatihan dan sistem kekeluargaan yang menilai prestasi.<br></br>
(2) Membuat Sistem Kerja Nasional yang teruji dan mewakili kinerja dan prestasi.<br></br>
(3) Membangun Hubungan Baik dengan Badan Pemerintahan Terkait secara Vertikal (Top Down).<br></br>
(4) Bekerja dengan Target Terukur untuk seluruh Divisi.<br></br>
(5) Buka Cabang Pertama tahun 2020.
</h5>
</div> */}
<div className="produkKamiContainer">
<div className="previewProductTitle">
<div className="previewProduct">
<h2>Produk Kami</h2> 
<div className="spaceKosongVisi"></div>
</div>
</div>

<div className="cardPreviewProduct">
<CardDeck style={{ width: '90rem' }}>
  <Card >
    <Card.Img variant="top" src="revisibondeck.jpeg" />
    <Card.Body>
      <Card.Title className="text-center p-3"><h4>Bondeck Akustik</h4></Card.Title>
    </Card.Body>

  </Card>
  <Card>
    <Card.Img variant="top" src="hollow.png" />
    <Card.Body>
      <Card.Title className="text-center p-3"><h4>Hollow</h4></Card.Title>
    </Card.Body>
    
  </Card>
  <Card>
    <Card.Img variant="top" src="trimdeck.png" />
    <Card.Body>
      <Card.Title className="text-center p-3"><h4>Spandeck</h4></Card.Title>
    </Card.Body>
   
  </Card>

  <Card>
    <Card.Img variant="top" src="senggalvanis.png" />
    <Card.Body>
      <Card.Title className="text-center p-3"><h4>Seng Galvanis</h4></Card.Title>
    </Card.Body>
   
  </Card>

  <Card>
    <Card.Img variant="top" src="zincalum.png" />
    <Card.Body>
      <Card.Title className="text-center p-3"><h4>Atap Gelombang Warna</h4></Card.Title>
    </Card.Body>
   
  </Card>
</CardDeck>
</div>
<h5><a href="/ProductList" style={{justifyContent:'flex-end',display:'flex',marginRight:'30px',color:'white'}}>Lihat Selengkapnya</a></h5>

</div>

<div className="customerRewardContainer">
<div className="customerReward"  style={{textAlign:'center',marginBottom:'50px'}}>
<h2>Hadiah Customer</h2> 
<div className="spaceKosongVisi" style={{marginTop:'-24px'}}></div>
<h5 style={{marginLeft:'20%',marginRight:'20%'}}>Kami sangat menghargai customer kami, maka dari itu setiap customer kami akan berkesempatan memenangkan berbagai macam hadiah seperti dibawah ini. <br></br>makanya ayo belanja di PT. Harapan Fajar Gemilang!</h5>
<img className="imageCustomerReward" src="reward.png" style={{}}></img>
</div>
</div>

<div className="galleryContainer">
<div className="gallery" style={{marginBottom:'20px'}}>
  <h2 style={{textAlign:'center'}}>Gallery</h2>
  <div className="spaceKosongVisi" style={{marginTop:'-24px'}}></div>

</div>
<CardDeck style={{margin:'20px'}}>
  <Card>
    <Card.Img variant="top" src="galeri1.jpeg" />
  </Card>
  <Card>
    <Card.Img variant="top" src="galeri2.jpeg" />
  </Card>
  <Card>
    <Card.Img variant="top" src="galeri3.jpeg" />
  </Card>
  <Card>
    <Card.Img variant="top" src="galeri4.jpeg" />
  </Card> 
</CardDeck>

<CardDeck style={{margin:'20px'}}>
  <Card>
    <Card.Img variant="top" src="galeri5.jpeg" />
  </Card>
  <Card>
    <Card.Img variant="top" src="galeri6.jpeg" />
  </Card>
  <Card>
    <Card.Img variant="top" src="galeri7.jpeg" />
  </Card>
  <Card>
    <Card.Img variant="top" src="galeri8.jpeg" />
  </Card> 
</CardDeck>

<CardDeck style={{margin:'20px'}}>
  <Card>
    <Card.Img variant="top" src="galeri9.jpeg" />
  </Card>
  <Card>
    <Card.Img variant="top" src="galeri10.jpeg" />
  </Card>
  <Card>
    <Card.Img variant="top" src="galeri11.jpeg" />
  </Card>
  <Card>
    <Card.Img variant="top" src="galeri12.jpeg" />
  </Card> 
</CardDeck>
</div>

<Footers3></Footers3> 
        </div>
        </div>
        
    )
} 
export default Home;